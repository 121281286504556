.login-container {
    width: 100%;
    height: 100vh;
    /* background: url(./abc11.png) no-repeat; */
    background-color: black;
    background-position: center;
    background-size: cover;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    padding: 0 15px; /* Added padding for mobile */
    /* margin-top: 60px; */
}

.login-card {
    width: 100%;
    max-width: 400px; /* Ensure a max-width for larger screens */
    height: auto;
    padding: 20px;
    border:1px solid black;
    background: rgb(212, 140, 32);
    /* backdrop-filter: blur(20px); */
    box-shadow: 0 0 15px rgba(10, 10, 11, 0.8);
    color: white;
    margin: auto;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    /* border: 3px solid rgba(255, 255, 255, 0.5); */
}

.login-card > h2 {
    color: white;
    font-weight: 700;
    font-size: 1.8em;
    text-transform: uppercase;
    padding: 10px 0;
    text-align: center;
}

.login-form-group {
    position: relative;
    width: 100%;
    margin: 20px 0;
    border-bottom: 3px solid black;
}

.login-form-group .password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: white;
    font-size: 1.2em;
}

.login-form-group input {
    width: 100%;
    height: 50px;
    /* border: 2px solid red; */
    padding: 0 35px 0 10px;
    font-size: 1.1em;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
}

.login-form-group label {
    position: absolute;
    top: 40%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 1.1em;
    color: white;
    transition: 0.5s;
}

/* input:focus ~ label,
input:valid ~ label {
    top: -5px;
} */

.login-form-group .fa-envelope,
.login-form-group .fa-lock {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color:white;
    font-size: 1.2em;
}

p {
    text-align: center;
    color: white;
    margin: 10px 0;
}

p > a {
    text-decoration: none;
    color: white;
    font-weight: 600;
}

p > a:hover {
    text-decoration: underline;
    font-style: italic;
}

#btn {
    width: 100%;
    max-width: 250px;
    border-radius: 40px;
    border: none;
    font-size: 18px;
    padding: 10px 10px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 20px 0;
    cursor: pointer;
    transition: all 0.5s;
}

#btn:hover {
    background: rgba(21, 47, 220, 0.8);
    color: white;
}

/* Media queries for mobile view */
@media (max-width: 768px) {
    .login-card {
        width: 90%;
        padding: 20px;
        margin-top: 30px;
    }

    h2 {
        font-size: 1.5em;
    }

    .login-form-group input {
        height: 45px;
        font-size: 1em;
    }

    .login-form-group label {
        font-size: 1em;
    }

    .login-form-group .password-toggle {
        font-size: 1.1em;
    }

    #btn {
        width: 100%;
        max-width: none;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .login-card {
        width: 95%;
    }

    h2 {
        font-size: 1.2em;
    }

    .login-form-group input {
        height: 40px;
        font-size: 0.9em;
    }

    .login-form-group label {
        font-size: 0.9em;
    }

    .login-form-group .password-toggle {
        font-size: 1em;
    }

    #btn {
        font-size: 14px;
    }
}
